/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { HomeDiv } from "./styles";



function Home() {
 
  return (
    

    <HomeDiv>
    <div className="box">
    <h2>CHINALCO PROCEDIMIENTOS</h2>
    <ol className="ol">
        <li className="li">
          <a className="laboratorio-link" href="/cambio-de-wet-end-de-bomba-de-ciclones">Cambio de wet end de bomba de ciclones</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-revestimiento-del-bowl-y-mantle-de-chancadora-pebbles"> Cambio de revestimiento del bowl y mantle de chancadora pebbles</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-lift-pads-del-molino-SAG"> Cambio de lift pads del molino SAG</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-faja-transportadora"> Cambio de faja transportadora</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-rodamiento-de-ciguenal-de-bomba-geho"> Cambio de rodamiento de cigüeñal de bomba GEHO</a>
        </li>
        <li className="li">
          <a className="laboratorio-link" href="/cambio-de-corona-rodamientos-del-sistema-de rastras-del-espesador"> Cambio de corona-rodamientos del sistema de rastras del espesador</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-bobinas-de-estator-en-molino-SA">Cambio de bobinas de estator en molino SAG</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-revestimiento-del-molino-SAG">Cambio de revestimiento del molino SAG</a>
        </li>
        <li className="li">
           <a className="laboratorio-link" href="/cambio-de-componentes-del-sistema-de-prensado">Cambio de componentes del sistema de prensado (ruedas planas y acanaladas)</a>
        </li>
        <li className="li">
          <a className="laboratorio-link" href="/cambio-de-sistema-hidraulico">Cambio de sistema hidráulico</a>
        </li>
    </ol>
  </div>
  </HomeDiv>
  );
}

export default Home;
