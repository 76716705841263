/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";

import { Container, PoweredBy } from "./styles";
import VergeViewer from "../../components/VergeViewer";
import VergePreloader from "../../components/VergePreloader";
import VergeLockPortrait from "../../components/VergeLockPortrait";


function CambioRevestimientoPebbles() {
 
  return (
    <Container>
      <VergeViewer
        src="/applications/cambio_revestimiento_pebles/cambio_revestimiento_pebles.html"
        title="Alineamiento"
      />
     
      
      {/* <VergeMessage />
      
      <PoweredBy src="/applications/Alineamiento_eje/imagenes/powered_by.png" alt="Potenciado por Tecsup" /> */}
     
      <VergeLockPortrait />
     {/*  <VergeFullScreen2/> */}

     {/*  <VergePreloader /> */}
    </Container>
  );
}

export default CambioRevestimientoPebbles;
