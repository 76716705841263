/* import React from 'react';
import ReactDOM from 'react-dom/client';

import V3DApp from './V3DApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<V3DApp/>); */

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

