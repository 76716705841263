/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FaExpand } from 'react-icons/fa';
import { Container } from './styles';



function VergeFullScreen() {

  return (
    <Container id="fullscreen_button">
      <FaExpand />
    </Container>
  );
}

export default VergeFullScreen;