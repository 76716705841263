import React from "react";
import { GlobalStyle } from "./Styles";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import CambioWetEnd from "./pages/CambioWetEnd";
import CambioRevestimientoPebbles from "./pages/CambioRevestimientoPebbles"
import CambioLiftPads from "./pages/CambioLiftPads"
import logo from './logo.svg';
import './App.css';
import CambioFajaTransportadora from "./pages/CambioFajaTransportadora";
import Home from "./pages/Home/Home";
import CambioBombaGeho from "./pages/CambioBombaGeho";
import CambioCorona from "./pages/CambioCorona";
import CambioComponentes from "./pages/CambioComponentes";


function App() {
  return (
    <div className="App">
       <Router>
        
       <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="/cambio-de-wet-end-de-bomba-de-ciclones">
            <CambioWetEnd/>
          </Route>
          <Route path="/cambio-de-revestimiento-del-bowl-y-mantle-de-chancadora-pebbles">
            <CambioRevestimientoPebbles/>
          </Route>
          <Route path="/cambio-de-lift-pads-del-molino-SAG">
            <CambioLiftPads/>
          </Route>
          <Route path="/cambio-de-faja-transportadora">
            <CambioFajaTransportadora/>
          </Route>
          <Route path="/cambio-de-rodamiento-de-ciguenal-de-bomba-geho">
            <CambioBombaGeho/>
          </Route>
          <Route path="/cambio-de-corona-rodamientos-del-sistema-de rastras-del-espesador">
            <CambioCorona/>
          </Route>
          <Route path="/cambio-de-bobinas-de-estator-en-molino-SAG">
            <CambioFajaTransportadora/>
          </Route>
          <Route path="/cambio-de-revestimiento-del-molino-SAG">
            <CambioFajaTransportadora/>
          </Route>
          <Route path="/cambio-de-componentes-del-sistema-de-prensado">
            <CambioComponentes/>
          </Route>
          <Route path="/cambio-de-sistema-hidraulico">
            <CambioFajaTransportadora/>
          </Route>
          </Switch>
          
          </Router>
          <GlobalStyle/>
    </div>
  );
}

export default App;
