import styled from 'styled-components';


export const HomeDiv = styled.div`

background: #142D4C;
width: 100vw;
height: 100vh;

`;
