/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";

import { Container, PoweredBy } from "./styles";
import VergeViewer from "../../components/VergeViewer";
import VergePreloader from "../../components/VergePreloader";
import VergeLockPortrait from "../../components/VergeLockPortrait";


function CambioBombaGeho() {
 
  return (
    <Container>
      <VergeViewer
        src="/applications/Cambio_Rodamiento_Bomba Geho/Cambio_Rodamiento_Bomba Geho.html"
        title="Alineamiento"
      />
     
      
      {/* <VergeMessage />
      
      <PoweredBy src="/applications/Alineamiento_eje/imagenes/powered_by.png" alt="Potenciado por Tecsup" /> */}
     
      <VergeLockPortrait />
     {/*  <VergeFullScreen2/> */}

     {/*  <VergePreloader /> */}
    </Container>
  );
}

export default CambioBombaGeho;
